















































import { Vue, Component, Ref } from 'vue-property-decorator';
import ArchiveCardVersionPanel from './ArchiveCardVersionPanel/ArchiveCardVersionPanel.vue';
import VersionsTable from './VersionsTable/VersionsTable.vue';
import { dateTimeToStr } from '@/utils/time_format';

enum Period {
  Week,
  Month,
  Year,
}

@Component({
  components: {
    ArchiveCardVersionPanel,
    VersionsTable
  }
})
/**
 * @group Archive Card
 * Archive tab content
 */
export default class ArchiveCard extends Vue {
  @Ref() scrollList!: HTMLDivElement

  selectedPeriod: Period = Period.Week

  versions = [
    {
      id: 1, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 2, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 3, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 4, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 5, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 6, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 7, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 8, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 9, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 10, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 11, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 12, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 13, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 14, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
    {
      id: 15, date: dateTimeToStr(new Date()), circuitsCount: Math.ceil(Math.random() * 10), scenariosCount: Math.ceil(Math.random() * 10), activeLamps: Math.ceil(Math.random() * 100)
    },
  ]

  get periods() {
    return [
      { name: this.$tc('main.week'), value: Period.Week },
      { name: this.$tc('main.month'), value: Period.Month },
      { name: this.$tc('main.year'), value: Period.Year },
    ];
  }

  searchPhrase = ''
}
