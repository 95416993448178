






import { Component, Vue } from 'vue-property-decorator';
import { dateTimeToStr } from '@/utils/time_format';

@Component
export default class ArchiveCardVersionPanel extends Vue {
  get currentVersionDateTime() {
    return dateTimeToStr(new Date());
  }
}
