































// import { dateTimeToStr } from '@/utils/time_format';
import { Component, Vue, Prop } from 'vue-property-decorator';
// import { capitalize } from '@/utils/string_format';

@Component
export default class VersionsTable extends Vue {
  @Prop({ required: true, type: Array }) versions!: any

  @Prop({ default: false, type: Boolean }) pending!: boolean

  get headers() {
    return [
      {
        // text: capitalize(this.$t('main.date') as string),
        text: 'Data',
        value: 'date',
        class: 'sticky-header-plain',
      },
      {
        // text: capitalize(this.$tc('main.circuit', 2)),
        text: 'Ilość obwodów',
        value: 'circuitsCount',
        class: 'sticky-header-plain',
      },
      {
        // text: capitalize(this.$tc('main.scenario', 2)),
        text: 'Ilość scenariuszy',
        value: 'scenariosCount',
        class: 'sticky-header-plain',
      },
      {
        // text: capitalize(this.$tc('main.lamp', 2)),
        text: 'Ilość aktywnych lamp',
        value: 'activeLamps',
        class: 'sticky-header-plain',
      },
      {
        text: '',
        value: 'icon',
        class: 'sticky-header-plain',
      },
    ];
  }

  // get items() {
  //   return this.versions
  //     .map((n) => ({
  //       id: n.id,
  //       type: n.telemetry.status === 0 ? 'notification' : 'error',
  //       icon: n.telemetry.status === 0 ? 'mdi-message' : 'mdi-alert',
  //       device: n.object_id,
  //       errorMessage: n.telemetry.status === 0 ? '' : this.$t(`deviceErrors[${n.telemetry.status}]`),
  //       timestamp: dateTimeToStr(n.timestamp)
  //     }));
  // }
}
